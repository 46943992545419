import type { Language } from '~/ts/types/language'
import { useUserStore } from '~/stores/user'
import { useLanguageStore } from '~/stores/language'
import navigateToHome from '~/helpers/navigateToHome'
import middlewareCheckContext from '~/helpers/middlewareCheckContext'

export default defineNuxtRouteMiddleware((to, from) => {
    if (useError().value || !middlewareCheckContext(to)) {
        return
    }

    if (useUserStore().isAuthenticated) {
        return navigateToHome(undefined, to)
    }

    // Локалізація
    const toLang = to.params.language as Language
    const fromLang = from.params.language as Language
    const languageStore = useLanguageStore()

    if (toLang) {
        // Очищення мови, якщо була явно встановлена мова за замовчуванням чи мова не підтримується
        if ((toLang === languageStore.defaultLanguage) || !languageStore.isSupportedLanguage(toLang)) {
            to.params.language = ''

            return to
        }
    } else if (
        isUndefined(toLang)
        && fromLang
        && (fromLang !== languageStore.defaultLanguage)
    ) {
        // Встановлення попередньої мови, якщо це перехід через навігацію
        // Коректно працює у випадку навігації з використанням RouteLocationNamedRaw, тоді явно невказаний параметр language буде undefined (в іншому випадку може бути - '')
        to.params.language = fromLang

        return to
    }
})
